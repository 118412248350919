// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Poppins&display=swap');


@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  font-size: 59pt;
  src: url(../public/fonts/Poppins/Poppins-Medium.ttf) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 59pt;
  font-display: swap;
  src: url(../public/fonts/Poppins/Poppins-Medium.ttf) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 59pt;
  font-display: swap;
  // src: url(../public/fonts/Montserrat/Montserrat-Bold.ttf) format("woff2");
  src: url(../public/fonts/Poppins/Poppins-Bold.ttf) format("woff2");
  // src: url(../public/fonts/Montserrat/Montserrat-SemiBold.ttf) format("woff2");
}

* {
  box-sizing: border-box;
  font-family: "Poppins" !important;
}


$success-color: #1478DD;

.bg-none {
  background-color: transparent;
}

.bx-shadow {
  box-shadow: 7px 10px 29px -11px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 7px 10px 29px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 10px 29px -11px rgba(0, 0, 0, 0.75);
}

a {
  color: black !important
}

a:hover {
  color: #0D6EFD !important
}

.ghost-bg {
  color: $success-color !important;
  border: 2px solid $success-color !important;
  font-weight: bold !important;
  width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.no-box-shadow {
  button {
    border: none !important;
    box-shadow: none !important;
  }
}

::placeholder,
input.form-control,
textarea.form-control {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5A5A5A
}

.line-space {
  letter-spacing: 2px !important;
}

.btn {
  border-radius: 3px !important;
}

.btn-success {
  background-color: #02af74 !important;
}

.btn-ghost {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.btn-ghost:hover {
  box-shadow: rgb(4 17 29 / 25%) 1px 2px 10px 0px;
}

.trans {
  transition: all .2s linear;
}

.ghostBorder {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  // width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.btn-ghost-small {
  border-radius: 10.6px;
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  font-size: 13.34px;
}

.btn-ghost-sharp {
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  font-size: 13.34px;
}

.btn-ghost-unchecked {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: transparent;
  color: black;
  border: 2px solid #d2d2d2 !important;
  font-weight: bold;
  width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.nrml-wdth {
  border-radius: 10.6px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: $success-color;
  color: white;
  border: 2px solid $success-color !important;
  font-weight: bold;
  font-size: 13.34px;
  letter-spacing: 2px;
  width: fit-content !important
}

.btn-green {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: $success-color;
  color: white;
  border: 2px solid $success-color !important;
  font-weight: bold;
  width: 100%;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.btn-grey {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: #d2d2d2;
  color: black;
  // border: 2px solid $success-color !important;
  font-weight: bold;
  width: 100%;
  font-size: 13.34px;
  letter-spacing: 2px;
}


h4.fleatoName {
  color: #1478DD !important
}

.dJLIRr .ais-SearchBox-submit {
  float: right;
  border: none;
  padding: 6px 2px !important;
  background: #f1f1f1 !important;
  color: black;
  font-size: 1rem;
  border-radius: 5px !important;

  cursor: pointer;
}

.bold {
  font-weight: 600 !important;
}


.fw-bold {
  font-weight: 600 !important;
  font-size: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-black,
btn-black:hover {
  background: #000;
  border: 1px solid #000;
}

.blue-bg,
.blue-bg {
  background: #1478DD !important;
}

.white-bg {
  background: #ffffff;
}


.border-blue {
  border: 1px solid #1478DD !important;
}

.border-grey {
  border: 1px solid #EAEAEA;
}

.text-blue,
.text-blue:hover {
  color: #1478DD !important
}

.text-danger,
.text-danger:hover {
  color: #F41E14 !important;
}

.dJLIRr .ais-SearchBox-submit:hover {
  color: #1478DD !important;
}

.dJLIRr .ais-SearchBox-input {
  padding: 6px 10px;
  font-size: 1rem;
  border: 0;
  float: left;
  width: 92%;
  background: #f1f1f1 !important;
  border-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;

}


.footer-col h4 {
  cursor: pointer
}

.uppercase {
  text-transform: uppercase;
}

.btn-danger {
  background-color: #F41E14 !important
}

.border-danger {
  border-color: #F41E14 !important;
}

.dark-text {
  color: #242424 !important;
}

.grey-text {
  color: #707070 !important;
}

.small-title {
  font-size: 16px !important;
}

.medium-text {
  font-size: 15px;
}

.small-text {
  font-size: 13px;
}

.jodit-status-bar {
  visibility: hidden;
}

.btn-ghost:hover {
  color: $success-color;
}

.btn-ghost-unchecked:hover {
  color: $success-color;
}


//Google tag manager css trigger classes
.gtm-walletpopup {}

.gtm-loginpopup {}

.gtm-wallet-setup {}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 300;
  color: #2c2c2c;
  font-size: 0.95rem;
  background: #ffffff;
}

table td,
table th {
  color: #2c2c2c;
}

a {
  color: #000 !important;
  // font-weight: bold !important;
  font-size: 14px !important;
  text-decoration: none !important;

  // letter-spacing: 2px !important;
  &:hover {
    color: #1478DD !important
  }
}

.no-linespace {
  letter-spacing: 0 !important;
  font-size: 14px !important;
}

.link {
  color: #1478DD !important;
}

h1 {
  font-size: 2.2rem;
  font-weight: 600 !important;
}

h2 {
  font-size: 1.8rem !important;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

h3 {
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
  font-weight: 600 !important;
}

h4 {
  font-size: 1rem !important;
  color: rgb(0, 0, 0);
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-md-bold {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600;
}

.text-normal {
  color: #000000;
  position: relative !important;
  left: 30px !important;
}

.text-normal::before,
.text-normal::after {
  display: none;
}

.white-bg {
  background-color: rgb(255, 255, 255);
}

.hover-green-bg {
  &:hover {
    background-color: #20db89 !important;
  }
}

div.rce-mbox {
  border-radius: 25px 25px 25px 0px !important;
  background: white !important;
  padding: 20px;
  border: 2px solid #1478DD !important;

}

div.rce-mbox.rce-mbox-right {
  border-radius: 25px 25px 0px 25px !important;
  background: white !important;
  padding: 20px;
  border: 2px solid #1478DD;

}

/* width */
// ::-webkit-scrollbar {
//   width: 6px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   background: #e4edff;
//   border-radius: 10px;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #1478DD;
//   border-radius: 10px;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #1478DD;
// }



.text-white {
  color: #fff;
}

.text-error {
  color: #f00;
}

.text-white-light {
  color: #d3d3d3;
}

.text-blue {
  color: #2081e2 !important;
}

.font-default {
  font-size: 1rem;
}

.font-md {
  font-size: 0.9rem;
}

.font-sm {
  font-size: 0.8rem;
}

.max-w-100 {
  max-width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.border {
  border: none;
}

.btn-light {
  background-color: #dbdbdb !important;
}

.border-green {
  border-radius: 10px;
  background-color: #20db89;
  color: white;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: bold;
}

.border-orange {
  border-radius: 10px;
  background-color: #ffc700;
  color: white;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: bold;
}

.menu li {
  a {
    font-weight: 600;
    color: #000 !important;
    font-size: 0.9rem;

    &:hover {
      text-decoration: none;
    }

    @media (max-width: 1199px) {
      font-size: 0.8rem;
    }
  }
}

.carousal-info {
  a {
    font-weight: bold !important;
    color: #1478DD !important;
    font-size: 20px !important;
  }
}

.main-content {
  background-color: #f5f5f5 !important;
}

.mt-15 {
  margin-top: 0.35rem !important;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
  width: 250px;
}

.right-panel {
  margin-left: 260px;
}

.ais-NumericMenu-labelText {
  margin-left: 10px;
}

.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}

.ais-Hits {
  display: flex;
  // flex-wrap: wrap;
  // padding: 0;
}

.ais-Hits-list {
  display: flex;
  width: 500 !important;
  overflow: hidden;
  // flex-wrap: wrap;
  // flex-direction: row;
}

.ais-Hits {
  // @media (max-width: 1199px) {
  //   height: 1600px;
  //   overflow: auto;
  // }
}

@media (min-width: 992px) {
  .productDetailMain {
    // height: 640px !important;
  }

  .productDetails {
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .productDetails::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 425px) {
  .artistData {
    margin-top: 0 !important;
    padding-left: 2rem !important;
  }
}

.ais-Hits-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;

  @media (max-width: 1200px) {
    width: 16.6666vw;
  }

  @media (max-width: 768px) {
    width: 27.777vw;
  }

  @media (max-width: 640px) {
    width: 41.6667vw;
  }

  @media (max-width: 424px) {
    width: 83.3333vw;
  }
}


.ais-InfiniteHits-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

li::marker {
  color: transparent;
}

.ais-InfiniteHits-item {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all .1s linear;

  @media (max-width:600px) {
    width: 100%;
  }

  @media (min-width:600px) {
    width: 50%;
  }

  @media (min-width: 920px) {
    width: 25%;
  }
}

.ais-CurrentRefinements-label {
  display: none;
}

.ais-CurrentRefinements-category {
  border-radius: 5px;
}

.ais-CurrentRefinements-delete {
  border-radius: 50%;
}

.ais-InfiniteHits-item:hover {
  transform: translateY(-5px);

}

.recent-drop {
  .ais-InfiniteHits {
    height: 1460px;
    overflow: hidden;

    .ais-InfiniteHits-list {
      display: flex;

      .ais-InfiniteHits-item {
        justify-content: center;

        .card-wrapper {
          width: 100% !important;

          .card {
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media screen and (min-width:920px) {
  .recent-drop {
    .ais-InfiniteHits {
      height: 390px;
      overflow: hidden;

      .ais-InfiniteHits-list {
        display: flex;

        .ais-InfiniteHits-item {
          justify-content: center;

          .card-wrapper {
            width: 100% !important;

            .card {
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

// Modal Css
.bidModal,
.profileModal,
.loginModal,
.redeemModal,
.addressModal {
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0;
  border: 0;
  box-shadow: rgb(0 0 0 / 32%) 0px 3px 6px;
}

.circleModal {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 0;
  border: 0;
  outline: none;
  border-radius: 50% !important;
  box-shadow: rgb(0 0 0 / 32%) 0px 3px 6px;
  background-color: #fff;
  overflow: hidden;
}

.circleModal:focus {
  border: 0;
  outline: none;
}


.bidModal {
  width: 46%;
}

.profileModal {
  width: 90%;
}

@media screen and (min-width: 720px) {
  .profileModal {
    width: 60%;
  }

  .circleModal {
    width: 500px;
    height: 500px;

  }
}

.ReactModal__Overlay--after-open {
  z-index: 12;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

@media (max-width: 991px) {
  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo svg {
      max-width: 25px;
    }
  }

  .bidModal,
  .loginModal {
    width: 90%;
    overflow: auto;
    height: auto !important;
  }

  .addressModal {
    width: 100%;
  }
}

@media (min-width: 768px) {
  // .ReactModal__Body--open,.ReactModal__Overlay--after-open {
  //   overflow-y: hidden;
  // }
}

@media (max-width: 600px) {
  // h1 {
  //   font-size: 1.4rem;
  // }

  h2 {
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    font-weight: 700;
  }

  h3 {
    font-size: 1.1rem;
    color: rgb(0, 0, 0);
    font-weight: 700;
  }

  h4 {
    font-size: 1rem;
    color: rgb(0, 0, 0);
    font-weight: 700;
  }
}

@media (max-width: 576px) {
  .loginModal {
    height: 100%;
  }
}

.loginModal {
  border-radius: 24px;
}

header {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.container.py-3.px-lg-4.max-w-100 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  border: none;
  position: relative;
}

input.newsletter-input {
  border: none !important;
  background: #f1f1f1 !important;
  color: #000 !important;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  width: 362px !important;
  border-radius: 10px;
}

.helper-container .accordion-topic {
  color: #000;
  line-height: 8px;
  padding: 15px;
  margin: 0;
  letter-spacing: 1.5px;

}

.helper-container .accordion-topic:hover {
  color: #1478DD !important;
  line-height: 8px;
  padding: 15px;
  margin: 0;
  letter-spacing: 1.5px;

}

.helper-container .active {
  color: #1478DD !important;
  line-height: 8px;
  width: 100%;
  padding: 15px;
  background-color: #1478dd35;
  border-radius: 0px 25px 25px 0px;
  margin: 0;
  letter-spacing: 1.5px;
}

.help-link.inactive {
  font-size: 20px !important;
  letter-spacing: 3.5px !important;
  color: #000;

}

.help-link.active {
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 3.5px !important;
  color: #1478DD !important
}

.paypal-buttons {
  z-index: 0;
}

.anchorPara {
  color: blue;
  cursor: pointer;
}

.anchorPara:active {
  color: #1478DD
}

.loader {
  animation: load 1.1s infinite linear;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: url('/images/close-circle.png');
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: cover;
}

.alarm {
  animation: tada 1.5s ease infinite;
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);

  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.snooze {
  animation: fade-up 2s infinite forwards;
}

@keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }

}

.lightning {
  animation: fade-up 1.5s infinite linear;
}

@keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

.categories-list a:hover {
  color: #1478DD !important
}

.primaryBorder {
  border: 1px solid #4ca5ff !important;
}

@media only screen and (max-width: 600px) {
  .categories-btn {
    top: 80px;
    left: 30px;

    .btn {
      font-size: 10px;
    }
  }

  .categories-banner-image {
    height: 200px;
  }

}

@media only screen and (min-width: 600px) {
  .categories-btn {
    top: 180px;
    left: 70px;
  }

  .categories-banner-image {
    height: 400px;

  }
}

@media only screen and (min-width: 768px) {

  .categories-btn {
    top: 160px;
    left: 70px;

    .btn {
      font-size: inherit;
    }
  }

  .categories-banner-image {
    height: 350px;

  }
}

@media only screen and (min-width: 992px) {

  .categories-btn {
    top: 300px;
    left: 110px;

    .btn {
      font-size: inherit;
    }
  }

  .overflow-lg-visible {
    overflow: visible !important;
  }

  .categories-banner-image {
    height: 400px;
  }

  .accessoriesBanner {
    .categories-btn {
      top: 330px;
      left: 50%;
      transform: translate(-25%);
    }
  }

  .paintingBanner {
    .categories-btn {
      top: 330px;
      left: 45%;
      transform: translate(-25%);
    }
  }

  .decorBanner {
    .categories-btn {
      top: 330px;
      left: 60%;
      transform: translate(20%);
    }
  }

  .allBanner {
    .categories-btn {
      top: 330px;
      left: 50%;
    }
  }


}

@media only screen and (min-width: 1200px) {
  .categories-btn {
    top: 300px;
    left: 110px;

    .btn {
      font-size: inherit;
    }
  }
}

.btn-white {
  padding: 15px !important;
  background-color: #fff;
}

.leaflet-pane {
  z-index: 6 !important;
}

.leaflet-top {
  z-index: 7 !important;
}

.leaflet-popup-content-wrapper {
  background: transparent !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  border-radius: 10px;
}

.leaflet-popup-tip {
  display: none
}

.leaflet-bottom.leaflet-right {
  z-index: 7;
}

.capitalize {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}

.hover-image:hover {
  box-shadow: 0 5px #000;
  transform: translateY(-5px);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  opacity: 0.85;
}

video {
  object-fit: fill !important;
}

.scroll-container {
  .ais-Hits-list {
    overflow: visible;

    .ais-Hits-item {
      margin: 0px;


      @media screen and (min-width:720px) {
        margin-right: 10px;
        width: 250px;
      }

      .pdt-cover {
        width: 100% !important;
        border-radius: 8px;

      }
    }
  }
}

.related-scroll-container {
  .ais-Hits-list {
    // overflow: hidden;

    flex-wrap: wrap;

    .ais-Hits-item {
      margin: 0px;


      @media screen and (min-width:720px) {
        margin-right: 10px;
        width: 250px;
      }

      .pdt-cover {
        width: 100% !important;
        border-radius: 8px;

      }
    }
  }
}

.popup-content {
  border-radius: 5px;
  padding: 0.8rem !important;
  width: fit-content !important;
}

.full-screen-content {
  background-color: #000 !important;
  z-index: 2;

  .carousel-slider {
    margin-top: 20px;

    .slider-wrapper {
      height: auto !important;

      .slider {
        .img-fluid {
          width: 80vh;
          height: 80vh;
        }
      }
    }

  }
}


//Artist banner style

.mobileBannerTitle {
  position: absolute;
  z-index: 1;

  h1 {
    color: #fff;
    text-transform: uppercase;
    background-color: #000;
    padding: 0 10px;
  }

  @media(max-width:600px) {
    bottom: -25px;
    left: 25%;
    transform: translate(4%);
  }

  @media(min-width:600px) {
    bottom: -50px;
    left: 50%;
    transform: translateX(-40%);
  }

  @media(min-width:920px) {
    bottom: 0;

  }

}

.mobileBannerBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.mobileBannerBackground:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;

  // background-color: rgba(0, 0, 0, 0.614);
  top: 0;
  left: 0;
  // filter: blur(10px);

  // background-color: white !important;
}

#maxImage {
  height: auto !important;

  .img-fluid {
    height: 100vh !important;
    width: 100vh !important;
    object-fit: contain;

  }
}

@media screen and (max-width:600px) {
  .shareComponent {
    position: absolute;
    margin-top: 25% !important;
    margin-left: 2%;
  }

  .greyHover {
    width: 100%;
  }


}

.ReactModal__Content {
  border-radius: 24px;
}

.artist-slider-pointer :hover {
  cursor: pointer;
}

@media screen and (min-width:720px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 340px);
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
  }


}

h1.heavy-bold.dark-text {
  line-height: 1.3;
  text-transform: capitalize;
}

h3.grey-text {
  line-height: 1.5;
}

.uppercase.text-danger {
  font-weight: 600;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.btn.btn-danger,
.btn.btn-white {
  padding: 0 !important;
  padding: 14px 20px !important;
  border-radius: 5px !important;
}

@media screen and (min-width:768px) {

  .scrollable-carousal-item:nth-child(1) {
    margin-left: 5px !important;
  }

  .scrollable-carousal-item:last-child {
    margin-right: 10px !important;
  }

  .greyHover {
    width: 100%;
  }
}

@media screen and (min-width:920px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 300px);
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
  }

  .greyHover {
    width: 49%;
  }

}

.hover-blue:hover {
  color: #1478DD;
}

@media (min-width:1380px) {
  .artistCollection {
    li {
      width: 330px !important;
    }
  }
}

.about-nft .badge {
  cursor: pointer;
  background: #d4d9de;
  border-radius: 50%;
  padding: 5px;
  line-height: 0;
  margin-left: 3px;

  svg {
    fill: #ffffff;
  }
}

.seller-profile {
  .blue-border {
    border: 2px solid #1478dd !important;
  }

  .active-tab {
    border-right: 5px solid #1478dd;
  }
}

.banner-container {
  height: 150px;

  .seller-profile-img {
    height: 150px;
    width: 150px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 25%);
    z-index: 3
  }
}



.greyHover:hover {
  background-color: #f5f5f5;

}

.mediumSize {
  font-size: 16px;
}

.deohmg .radio-select .dropdown-trigger .tag-item {
  width: 90% !important;

}

@media (min-width:768px) {
  .deohmg .radio-select .dropdown-trigger .tag-item {
    width: 95% !important;

  }
}

.close-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    margin: 0 10px;
    padding: 1px;
    background-color: #1478DD;
    border-radius: 50%;
    fill: #fff;

  }
}

.underlined {
  border-bottom: 2px solid #d2d2d2;
}

.list-items {
  cursor: pointer;
}

.active-list-item {
  background-color: #f1f1f1;
}

.list-items:hover {
  background-color: #f1f1f1;
}

.react-toggle-track {
  background-color: #d2d2d2 !important;

}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #d2d2d2;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1478DD !important;
}

.react-toggle-thumb {
  box-shadow: none !important;
}

.react-toggle-thumb {
  border: none !important;
}

.primary-border {
  left: 0;
  top: 0;
  border: 0.25em solid #1478dd;
  border-bottom-color: transparent !important;
  border-left-color: transparent;
  animation: 1.2s linear infinite spinner-border;
}

.merchandise {
  .accordion-header {
    .accordion-button {
      padding: 0
    }

    .accordion-button::after {
      margin-right: 5%;

    }
  }
}

input[type='range']::-webkit-slider-thumb {
  background-color: #F41E14;
  box-shadow: none !important;
}

input[type='range']::-webkit-slider-thumb:active {
  background-color: #F41E14;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 94, 0.5) !important
}


.form-check-input:checked {
  background-color: #F41E14;
  border-color: #F41E14;
}

.bg-danger {
  background-color: #f41e14 !important;
}

.input-group-text {
  border-radius: 0 5px 5px 0 !important;
}

.btn-light {
  background-color: #fff !important;
  color: #f41e14 !important;
  font-weight: 550;
  font-size: 17px;
}


.circluar-bage-home {
  background-color: #dedede;
  color: #242424;
  text-transform: capitalize;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
}



.time-line .dashedLine::after {
  content: '';
  height: 90px;
  position: absolute;
  bottom: -100px;
  border-left: 3px dashed #dedede;
}

.time-line .red-dashedLine::after {
  border-left: 3px dashed #f41e14;

}

.dark-bg {
  background: #242424;
}

.footer-links,
.footer-links h4,
.footer-links a,
.footer-links h3 {
  color: #fff !important;
}

.footer li {
  margin-bottom: 0.2rem;
  font-size: .8rem;
}

.footer-links li a,
.footer-links .white-light {
  color: #e0e0e0 !important;
}

.footer-links .col-heading,
.footer .download-area h3 {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 1rem;
}

.footer-links .download-area {
  border-top: 1px solid #424242;
}

.footer .download-area .section-intro,
.app-stores {
  font-size: 0.9rem;
}

@media (min-width:920px) {
  .footer .position-lg-absolute {
    position: absolute !important;
    right: -300px;
    top: -5px;
  }
}

.react-player__shadow {
  position: relative;
  z-index: 1;
}

.react-player__shadow:after {
  content: '';
  width: 100px;
  height: 100px;
  background-color: #000;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.border-none,
.outline-none {
  border: none !important;
  outline: none !important;
}